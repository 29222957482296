import React from "react";
import LoginImage from "../Assets/login.png";
import WavingHand from "../Assets/waving-hand.png";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full min-h-screen bg-slate-700 grid grid-cols-1 md:grid-cols-2 p-5 gap-5 place-items-center">
      <div className="flex justify-center items-center">
        <img
          src={LoginImage}
          alt="login"
          className="object-cover w-full h-full"
        />
      </div>
      <form className="w-full max-w-md flex flex-col items-center border p-6 bg-white rounded-md shadow-lg">
        <div className="flex flex-col mb-5 items-center text-center">
          <h1 className="flex text-2xl md:text-3xl text-[#001F3F] font-semibold mb-2">
            Hello, Welcome Back!{" "}
            <img src={WavingHand} alt="waving-hand" width={20} />
          </h1>
          <p className="text-gray-700 text-sm md:text-base">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          </p>
        </div>
        <div className="w-full flex flex-col mb-5">
          <label
            htmlFor="email"
            className="mb-2 text-left text-sm md:text-base"
          >
            Email
          </label>
          <input
            type="email"
            name="email"
            placeholder="Enter your email.."
            className="w-full p-2 text-lg rounded-md border border-gray-400"
          />
        </div>
        <div className="w-full flex flex-col mb-5">
          <label
            htmlFor="password"
            className="mb-2 text-left text-sm md:text-base"
          >
            Password
          </label>
          <input
            type="password"
            name="password"
            placeholder="Enter your password.."
            className="w-full p-2 text-lg rounded-md border border-gray-400"
          />
        </div>
        <div className="w-full flex flex-col items-center">
          <button className="bg-[#001F3F] w-full p-3 rounded-md text-white text-xl mb-2 hover:bg-[#003366] transition-colors">
            Sign In
          </button>
          <p className="text-sm md:text-base">
            Don't have an account?{" "}
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => navigate("/signup")}
            >
              Sign Up
            </span>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;
